import { makeStyles } from '@material-ui/core/styles';
import CalendarToday from '@material-ui/icons/CalendarToday';
import MaterialLinkButton from './material-link-button';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  icon: {
    marginLeft: 0,
    marginRight: theme.spacing(0.5),
    fontSize: 20,
  },
}));

const SubscribeToCalendarButton = (props) => {
  const classes = useStyles();

  return (
    <MaterialLinkButton
      className={classes.button}
      variant="outlined"
      color="primary"
      {...props}
    >
      <CalendarToday className={classes.icon} />
      Subscribe to Calendar
    </MaterialLinkButton>
  );
};

export default SubscribeToCalendarButton;
