import gql from 'graphql-tag';
import { eventListingAttributes } from './types/event';

export const cityQuery = gql`
  query CityQuery($slug: String!) {
    city(slug: $slug) {
      id
      name
      locality
      slug
      latitude
      longitude
      description
      resources
      territoriesJson
    }
  }
`;

export const cityEventsQuery = gql`
  query CityUpcomingEventsQuery($id: ID!, $filters: EventFilters, $limit: Int) {
    city(id: $id) {
      id
      name
      slug
      events(filters: $filters, limit: $limit, offset: 0) {
        nodes {
          ${eventListingAttributes}
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          offset
          count
        }
      }
    }
  }
`;

export const cityEventsPaginationQuery = gql`
  query CityUpcomingEventsPaginationQuery(
    $id: ID!,
    $filters: EventFilters,
    $limit: Int,
    $offset: Int
  ) {
    city(id: $id) {
      id
      events(filters: $filters, limit: $limit, offset: $offset) {
        nodes {
          ${eventListingAttributes}
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          offset
          count
        }
      }
    }
  }
`;

export const cityCalendarQuery = gql`
  query CityCalendarQuery($slug: String!) {
    city(slug: $slug) {
      id
      name
      slug
      timezone
    }
  }
`;

export const cityCalendarEventsQuery = gql`
  query CityCalendarEventsQuery($id: ID!, $start: DateTime!, $end: DateTime!) {
    city(id: $id) {
      id
      calendarEvents(start: $start, end: $end) {
        ${eventListingAttributes}
      }
    }
  }
`;

export const citiesQuery = gql`
  query CitiesQuery {
    cities {
      id
      name
      slug
    }
  }
`;

export const cityFieldCitiesQuery = gql`
  query CityFieldCitiesQuery {
    cities {
      id
      slug
      name
    }
  }
`;

export const primaryCityQuery = gql`
  query PrimaryCityQuery {
    me {
      id
      primaryCity {
        id
        name
        slug
      }
    }
  }
`;

export const cityMetaQuery = gql`
  query CityMetaQuery($slug: String!) {
    city(slug: $slug) {
      id
      name
      slug
    }
  }
`;

export const makeCityPrimaryMutation = gql`
  mutation MakeCityPrimaryMutation($id: ID!) {
    makeCityPrimary(id: $id) {
      id
      primaryCity {
        id
        slug
        name
      }
    }
  }
`;
