import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import _pick from 'lodash/pick';
import _each from 'lodash/each';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import PrimaryFilter from './primary-filter';
// import TypeFilter from './type-filter';
// import HostFilter from './host-filter';
// import CategoriesFilter from './categories-filter';
import { filterableRoute } from '../../utils/routes';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& > *': {
      marginBottom: theme.spacing(1),
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const defaultFilters = {
  filter: 'upcoming',
  // host: 'all',
  // categoryIds: [],
};

const CalendarFilterBar = ({ basePath, baseHref, baseAs, tab }) => {
  const classes = useStyles();
  const router = useRouter();
  
  const filters = useMemo(() => {
    return {
      ...defaultFilters,
      ..._pick(router.query, Object.keys(defaultFilters)),
      // categoryIds: router.query.categoryIds
      //   ? router.query.categoryIds.split(',')
      //   : defaultFilters.categoryIds,
    };
  }, [router.query]);

  const handleChange = useCallback((attribute, value) => {
    const newQuery = { ...filters };
    newQuery[attribute] = value;
    _each(Object.keys(newQuery), (key) => {
      // need isEmpty check because for some reason empty arrays don't ====
      if (newQuery[key] === defaultFilters[key] || _isEmpty(newQuery[key])) {
        delete newQuery[key];
      }
    });
    if (baseHref) {
      router.push(
        filterableRoute(`${baseHref}${basePath}`, newQuery),
        filterableRoute(`${baseAs}${basePath}`, newQuery)
      );
    } else {
      router.push(filterableRoute(basePath, newQuery));
    }
  }, [filters, baseHref, baseAs]);

  return (
    <div className={classes.wrapper}>
      {tab !== 'grid' && (
        <PrimaryFilter
          value={filters.filter}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

CalendarFilterBar.propTypes = {
  basePath: PropTypes.string.isRequired,
  baseHref: PropTypes.string,
  baseAs: PropTypes.string,
  tab: PropTypes.string.isRequired,
};

export default CalendarFilterBar;
