import PropTypes from 'prop-types';
import Link from 'next/link';
import Tab from '@material-ui/core/Tab';

const LinkTab = ({ as: linkAs, href, value, ...rest }) => {
  return (
    <Link href={href} as={linkAs} legacyBehavior>
      <Tab
        key={value}
        value={value}
        component="a"
        href={linkAs || href}
        {...rest}
      />
    </Link>
  );
};

LinkTab.propTypes = {
  as: PropTypes.string,
  href: PropTypes.string,
  value: PropTypes.string,
};

export default LinkTab;
