import PropTypes from 'prop-types';
import clsx from 'clsx';
import _map from 'lodash/map';
import { makeStyles } from '@material-ui/core/styles';
import MTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import LinkTab from './link-tab';

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.secondary.light,
    minHeight: 38,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  tabsInverted: {
    backgroundColor: theme.palette.primary.background,
    //color: '#fff',
  },
  tabIndicator: {
    backgroundColor: theme.palette.secondary.dark,
  },
  tabIndicatorInverted: {
    backgroundColor: theme.palette.primary.dark,
  },
  tab: {
    minWidth: 120,
    minHeight: 38
  },
}));

const Tabs = (props) => {
  const {
    options,
    tab,
    onTabChange,
    width,
    className,
    inverted,
    links,
    ...tabsProps
  } = props;
  const classes = useStyles();
  const centered = options.length <= 2 && isWidthDown('sm', width);
  const TabComponent = links ? LinkTab : Tab;

  return (
    <MTabs
      value={tab}
      onChange={onTabChange}
      scrollButtons={options.length <= 2 || isWidthUp('md', width) ? "auto" : "on"}
      variant={centered ? 'standard' : 'scrollable'}
      centered={centered}
      aria-label="scrollable tabs"
      classes={{
        root: clsx(classes.tabs, { [classes.tabsInverted]: inverted }),
        indicator: clsx(classes.tabIndicator, { [classes.tabIndicatorInverted]: inverted })
      }}
      className={className}
      {...tabsProps}
    >
      {_map(options, (tab) => {
        return (
          <TabComponent
            key={tab.value}
            disableRipple
            classes={{
              root: classes.tab,
            }}
            {...tab}
          />
        );
      })}
    </MTabs>
  );
};

Tabs.propTypes = {
  options: PropTypes.array,
  tab: PropTypes.string,
  onTabChange: PropTypes.func,
  width: PropTypes.string,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  links: PropTypes.bool,
};

Tabs.defaultProps = {
  inverted: false,
  links: false,
};

export default withWidth()(Tabs);
