import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import _omit from 'lodash/omit';
import ListIcon from '@material-ui/icons/List';
import TodayIcon from '@material-ui/icons/Today';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '../tabs';
import FilterBar from './filter-bar';
import { filterableRoute } from '../../utils/routes';
import MaterialLinkButton from '../material-link-button';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0, 1, 0),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
  },
  headerLeft: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    margin: 0,
    paddingRight: theme.spacing(2),
  },
  addButton: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  addIcon: {
    marginRight: theme.spacing(0.5),
  },
  tabs: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  titleLink: {
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

const getBasePath = (tab) => {
  if (tab === 'list') {
    return '/calendar';
  }
  return `/calendar/${tab}`;
};

const CalendarHeader = ({ title, tab, filters }) => {
  const classes = useStyles();

  const basePath = useMemo(() => getBasePath(tab), [tab]);
  const handleTabChange = useCallback((e, newTab) => {
    Router.push(filterableRoute(getBasePath(newTab), _omit(filters, 'filter')));
  });

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <MaterialLinkButton
            href="/events/new"
            color="primary"
            variant="contained"
            className={classes.addButton}
            size="small"
          >
            <AddIcon className={classes.addIcon} />
            Add Event
          </MaterialLinkButton>
        </div>

        <Tabs
          options={[
            {
              label: (
                <span className={classes.label}>
                  <ListIcon className={classes.labelIcon} />
                  List
                </span>
              ),
              value: 'list',
            },
            {
              label: (
                <span className={classes.label}>
                  <TodayIcon className={classes.labelIcon} />
                  Grid
                </span>
              ),
              value: 'grid',
            },
          ]}
          tab={tab}
          onTabChange={handleTabChange}
          className={classes.tabs}
        />
      </div>
      {filters && (
        <div className={classes.filterBar}>
          <FilterBar basePath={basePath} filters={filters} tab={tab} />
        </div>
      )}
    </div>
  );
};

CalendarHeader.propTypes = {
  title: PropTypes.node.isRequired,
  tab: PropTypes.string.isRequired,
  filters: PropTypes.object,
};

export default CalendarHeader;
