import PropTypes from 'prop-types';
import DropdownFilter from './dropdown-filter';

const options = {
  upcoming: 'Upcoming',
  rsvp: 'My RSVPs',
  managed: 'My Hosting',
  drafts: 'My Drafts',
  recent: 'Recently Added',
  past: 'Past',
};

const CalendarPrimaryFilter = ({ value, onChange }) => {
  return (
    <DropdownFilter
      attribute="filter"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

CalendarPrimaryFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CalendarPrimaryFilter;
