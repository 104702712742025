import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialLinkButton from '../../components/material-link-button';
import SubscribeToCalendarButton from '../../components/subscribe-to-calendar-button';
import CalendarAboutFooter from '../../components/calendar/about-footer';

const useStyles = makeStyles((theme) => ({
  addButton: {
    whiteSpace: 'nowrap',
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const CalendarFooter = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.buttons}>
        <MaterialLinkButton
          href="/events/new"
          color="primary"
          variant="contained"
          className={classes.addButton}
        >
          Add Event
        </MaterialLinkButton>

        <SubscribeToCalendarButton href="/calendar/subscribe" />
      </div>

      <CalendarAboutFooter />
    </Fragment>
  );
};

export default CalendarFooter;
