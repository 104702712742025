import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MaterialLink from '../material-link';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(4, 0, 4, 0),
  },
}));

const CalendarAboutFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Divider className={classes.divider} />

      <p>
        The Everyday Rides Calendar is a free to use public calendar that anyone can post to. Rides and events are posted to the calendar by users and are not sponsored by Everyday Rides.
        {' '}
        <MaterialLink href="/about">
          Learn more about Everyday Rides
        </MaterialLink>
      </p>
    </div>
  );
};

export default CalendarAboutFooter;
