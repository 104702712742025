import { useRef, useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  typeChip: {
    '& > span:first-child': {
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(0.5),
    },
  },
}));

const CalendarDropdownFilter = ({ attribute, options, value, onChange }) => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleItemClick = useCallback((newValue) => {
    onChange(attribute, newValue);
    handleClose();
  }, [attribute, onChange]);
  const active = value !== Object.keys(options)[0];

  return (
    <Fragment>
      <Chip
        aria-controls={`${attribute}-filter`}
        aria-haspopup="true"
        onClick={handleOpen}
        ref={buttonRef}
        className={classes.typeChip}
        label={(
          <Fragment>
            {options[value]}
            {' '}
            <ArrowDropDownIcon />
          </Fragment>
        )}
        variant="outlined"
        clickable
        color={active ? 'primary' : 'default'}
      />
      <Menu
        id={`${attribute}-filter`}
        anchorEl={buttonRef.current}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {_map(options, (value, key) => (
          <MenuItem key={key} value={key} onClick={() => handleItemClick(key)}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

CalendarDropdownFilter.propTypes = {
  value: PropTypes.string,
  attribute: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default CalendarDropdownFilter;
